<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: null,
      password: null,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.isLoading = true;
        console.log({
            username: this.email,
            password: this.password,
          })
        this.$store
          .dispatch("account/login", {
            username: this.email,
            password: this.password,
          })
          .then(() => {
            localStorage.setItem("after-login", true);
            this.$router.push("/");
            this.isLoading = false;
            this.$notify({
              message:
                "Welcome " + this.$store.getters["account/accountData"].name,
              position: "top-right",
              type: "info",
              duration: 5000,
            });
          })
          .catch((error) => {
            console.log(error)
            this.isLoading = false;
            // this.$store.dispatch('notification/error', error)
            this.$store.dispatch(
              "notification/error",
              "Kombinasi email & password anda salah"
            );
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-12">
            <div class="text-center">
              <h1 class="text-primary">KAOSNYAMAN LOGO HERE</h1>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to KaosNyaman.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div v-if="notification.message">
                    <b-alert
                      :variant="notification.type"
                      show
                      :class="
                        'alert-border text-center mt-4 px-4 mb-0 alert-border-' +
                          notification.type
                      "
                    >
                      <strong :class="'text-' + notification.type">
                        {{ notification.message }}
                      </strong>
                    </b-alert>
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Username"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Enter Username"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.email.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <label for="input-2">Password</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>
                    </b-form-group>
                    <div class="mt-3 text-end">
                      <b-button
                        type="submit"
                        variant="primary"
                        :disabled="isLoading"
                        class="w-sm"
                        ><b-spinner
                          style="width: 1rem;height: 1rem; margin-right: 5px;"
                          label="Loading..."
                          v-if="isLoading"
                        ></b-spinner>
                        Log In</b-button
                      >
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>
